<template>
  <div class="home">
    <Head></Head>
    <div class="home-titles">
      <p class="home-title">{{ $store.state.staticName.home_title_1 }}</p>
      <p class="home-default">{{ $store.state.staticName.home_subhead_1 }}</p>
    </div>
    <div class="home-customized">
      <!--      <div class="home-customized-left">-->
      <!--        <div class="home-customized-box" @click="goTitaniumProducts(CustomizedData[0].id)" v-if="CustomizedData[0]">-->
      <!--          <img :src="CustomizedData[0].abbreviation[0]">-->
      <!--          <div>-->
      <!--            <p>{{ CustomizedData[0].name }}</p>-->
      <!--          </div>-->
      <!--        </div>-->
      <!--        <div class="home-customized-box" @click="goTitaniumProducts(CustomizedData[1].id)" v-if="CustomizedData[1]">-->
      <!--          <img :src="CustomizedData[1].abbreviation[0]">-->
      <!--          <div>-->
      <!--            <p>{{ CustomizedData[1].name }}</p>-->
      <!--          </div>-->
      <!--        </div>-->
      <!--      </div>-->
      <div
        class="home-customized-center"
        v-for="(item, i) in CustomizedData"
        :key="'iii' + i"
      >
        <div
          class="home-customized-box"
          @click="goTitaniumProducts(item.id)"
          v-if="item"
        >
          <img :src="item.abbreviation[0]" />
          <div class="title">
            <p>{{ item.name }}</p>
          </div>
        </div>
      </div>
      <!--      <div class="home-customized-center">-->
      <!--        <div class="home-customized-box" @click="goTitaniumProducts(CustomizedData[1].id)" v-if="CustomizedData[1]">-->
      <!--          <img :src="CustomizedData[1].abbreviation[0]">-->
      <!--          <div>-->
      <!--            <p>{{ CustomizedData[1].name }}</p>-->
      <!--          </div>-->
      <!--        </div>-->
      <!--      </div>-->
      <!--      <div class="home-customized-center">-->
      <!--        <div class="home-customized-box" @click="goTitaniumProducts(CustomizedData[2].id)" v-if="CustomizedData[2]">-->
      <!--          <img :src="CustomizedData[2].abbreviation[0]">-->
      <!--          <div>-->
      <!--            <p>{{ CustomizedData[2].name }}</p>-->
      <!--          </div>-->
      <!--        </div>-->
      <!--      </div>-->
      <!--      <div class="home-customized-left">-->
      <!--        <div class="home-customized-box" @click="goTitaniumProducts(CustomizedData[3].id)" v-if="CustomizedData[3]">-->
      <!--          <img :src="CustomizedData[3].abbreviation[0]">-->
      <!--          <div>-->
      <!--            <p>{{ CustomizedData[3].name }}</p>-->
      <!--          </div>-->
      <!--        </div>-->
      <!--        <div class="home-customized-box" @click="goTitaniumProducts(CustomizedData[4].id)" v-if="CustomizedData[4]">-->
      <!--          <img :src="CustomizedData[4].abbreviation[0]">-->
      <!--          <div>-->
      <!--            <p>{{ CustomizedData[4].name }}</p>-->
      <!--          </div>-->
      <!--        </div>-->
      <!--      </div>-->
      <!--      <div class="home-customized-box">-->
      <!--        <img src="@/assets/logo.jpeg">-->
      <!--        <div>-->
      <!--          <p>定制化服务</p>-->
      <!--        </div>-->
      <!--      </div>-->
    </div>
    <div class="home-titles">
      <p class="home-title">{{ $store.state.staticName.home_title_2 }}</p>
      <p class="home-default">{{ $store.state.staticName.home_subhead_2 }}</p>
      <div class="goods-box">
        <div class="goods-list">
          <div
            class="goods-item"
            v-for="(item, i) in prcData"
            :key="'goods' + i"
          >
            <div class="img" v-if="item.tradeBanner">
              <img class="img" :src="item.tradeBanner[0]" />
            </div>
            <div class="goods-info">
              <div class="goods-title">
                {{ item.tradeName }}
              </div>
              <div class="goods-describe">
                {{ item.describe }}
              </div>
              <div class="goods-price">
                <div class="price">￥{{ item.tradePrice }}</div>
                <div class="btn-box">
                  <div class="btn" @click="toProduct(item)">
                    {{ $store.state.staticName.view_details }}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="more">
          <div class="more-btn" @click="handleClick">
            {{ $store.state.staticName.read_more }}
          </div>
        </div>
      </div>
      <!--新闻关于我们-->
      <div class="news-box">
        <div class="we-box">
          <div class="item-head">
            <div class="head-title">
              {{ $store.state.staticName.home_about_us }}
            </div>
            <div class="head-btn-box">
              <div class="head-btn" @click="toNewlist(ABOUT_US.ABOUT_US)">
                {{ $store.state.staticName.read_more }}
              </div>
            </div>
          </div>
          <div class="we-new-list">
            <div
              v-for="(item, i) in ABOUT_US.value"
              :key="'about' + i"
              :class="i > 0 ? 'we-itema' : 'we-item'"
              @click="toDetil(item)"
            >
              <div class="item-img" v-if="i < 1">
                <img :src="item.abbreviation[0]" />
              </div>
              <div class="item-info">
                <div class="info-title">
                  {{ item.title }}
                  <!--                  攀枝花市午跃科技有限公司于2017年08月01日成立法...-->
                </div>
                <div class="info-describe" v-if="i < 1">
                  {{ item.synopsis }}
                  <!--                  攀枝花市午跃科技有限公司于2017年08月01日成立。法定代表人覃美玲，公司经营范围包括：假肢、人工器官及植（介）入器械制造；无障碍设施生产、组装、维护；设计、攀枝花市午跃科技有限公司于2017年08月01日成立。法定代表人覃美玲，公司经营范围包括：假肢、人工器官及植（介）入器械制造；无障碍设施的大大挖....-->
                </div>
                <div class="info-date">
                  {{ item.time }}
                  <!--                  2020-12-07-->
                </div>
              </div>
            </div>
          </div>
        </div>
        <!--新闻-->
        <div id="news">
          <div class="item-head">
            <div class="new-header">
              <div
                class="head-title"
                :class="
                  leftData.INDUSTRY_TRENDS === 'INDUSTRY_TRENDS' ? 'active' : ''
                "
                @click="handleHang"
              >
                {{ $store.state.staticName.home_industry_trends }}
              </div>
              <div
                class="head-title"
                :class="
                  leftData.MEDIA_REPORT === 'MEDIA_REPORT' ? 'active' : ''
                "
                @click="handleMei"
              >
                {{ $store.state.staticName.media_report }}
              </div>
            </div>
            <div class="head-btn-box">
              <div
                class="head-btn"
                @click="
                  toNewlist(leftData.INDUSTRY_TRENDS || leftData.MEDIA_REPORT)
                "
              >
                {{ $store.state.staticName.read_more }}
              </div>
            </div>
          </div>
          <div class="new-list-box">
            <div
              class="new-item"
              v-for="(item, i) in leftData.value"
              :key="'new' + i"
              @click="toDetil(item)"
            >
              <div class="new-img" v-if="item.abbreviation">
                <img class="new-img" :src="item.abbreviation[0]" />
              </div>
              <div class="new-info">
                <div class="new-title">
                  {{ item.title }}
                  <!--                  攀枝花市午跃科技有限公司于2017年08月01日成立法定代表人覃美玲，公司经营范围包括假肢人...-->
                </div>
                <div class="new-new">
                  {{ item.synopsis }}
                  <!--                  攀枝花市午跃科技有限公司于2017年08月01日成立。法定代表人覃美玲，公司经营范围包括：假肢、人工器官及植（介）入器械制造；无障碍设施生产、组装、维护；设计、攀枝花市午跃科技有限公司于2017年08月01...-->
                </div>
                <div class="new-date">
                  {{ item.time }}
                  <!--                  2020-12-07-->
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <Foot>
    </Foot>
  </div>
</template>

<script>
import Head from "@/components/Head";
import Foot from "../components/Foot";
import { mapState } from "vuex";
export default {
  name: "Home",
  components: { Foot, Head },
  data() {
    return {
      CustomizedData: [],
      prcData: [],
      leftData: [],
      ABOUT_US: [],
      INDUSTRY_TRENDS: [],
      MEDIA_REPORT: [],
      page: {
        type: "CHINESE",
        pageSize: 6,
        currentPage: 1,
      },
      page1: {
        type: "CHINESE",
        pageSize: 6,
        currentPage: 1,
      },
    };
  },
  watch: {
    "$store.state.languageType": {
      handler(newVal) {
        this.prcData = [];
        this.page.currentPage = 1;

        this.getCustomizedData();
        this.getPrcData();
        this.getNewsData();
      },
      // deep: true // 深度监听
    },
  },
  created() {
    this.getCustomizedData();
    this.getPrcData();
    this.getNewsData();
  },
  computed: {
    ...mapState({
      languageType: (state) => state.languageType, // 语言类型
    }),
  },
  methods: {
    goTitaniumProducts(id) {
      this.$router.push({ path: `/titaniumproducts/${id}` });
    },
    ///queryNewsByLanguage
    getCustomizedData() {
      this.$axios({
        url: "/queryCUS_CustomizedFive",
        method: "GET",
        params: {
          type: this.languageType,
        },
      }).then((res) => {
        this.CustomizedData = res.data.value;
      });
    },
    getPrcData() {
      this.page.type = this.$store.state.languageType;
      this.$axios({
        url: "/queryCommodityBySix",
        method: "GET",
        params: this.page,
      }).then((res) => {
        let value = res.data.value.value;
        if (value !== null && value.length > 0) {
          this.prcData.push(...value);
        } else {
          this.$message.warning(
            this.$store.state.languageType === "CHINESE"
              ? "没有更多数据"
              : "No more data"
          );
        }
      });
    },
    getNewsData() {
      this.page1.type = this.$store.state.languageType;
      this.$axios({
        url: "/queryNewsByLanguage",
        method: "GET",
        params: this.page1,
      }).then((res) => {
        let value = res.data.value;
        this.ABOUT_US = value.ABOUT_US;
        this.ABOUT_US.ABOUT_US = "ABOUT_US";
        this.INDUSTRY_TRENDS = value.INDUSTRY_TRENDS;
        this.INDUSTRY_TRENDS.INDUSTRY_TRENDS = "INDUSTRY_TRENDS";
        this.MEDIA_REPORT = value.MEDIA_REPORT;
        this.MEDIA_REPORT.MEDIA_REPORT = "MEDIA_REPORT";
        this.leftData = this.INDUSTRY_TRENDS;
        // if (value !== null && value.length > 0){
        //   this.prcData.push(...value);
        // }else{
        //   this.$message.warning("没有更多数据")
        // }
      });
    },
    handleClick() {
      this.page.currentPage = this.page.currentPage + 1;
      this.getPrcData();
    },
    handleHang() {
      this.leftData = this.INDUSTRY_TRENDS;
    },
    handleMei() {
      this.leftData = this.MEDIA_REPORT;
    },
    toDetil(data) {
      console.log(data);
      this.$router.push({
        path: `/newdetails/${data.type}/${data.id}`,
      });
    },
    toNewlist(newType) {
      this.$router.push({
        path: `/newlist/${newType}`,
      });
    },
    toProduct(data) {
      this.$router.push({
        path: "/product",
        query: {
          c: data.tradeCategory,
          i: data.id,
        },
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.we-item,
.we-itema,
.new-item,
.home-customized-box {
  cursor: pointer;
}

.active {
  border-bottom: #1850af 1px solid !important;
  color: #1850af !important;
}

.home {
  display: flex;
  background-color: #fff;
  flex-direction: column;

  .home-titles {
    margin: {
      top: 74px;
      left: 130px;
      right: 130px;
      bottom: 30px;
    }
    text-align: center;

    .home-title {
      color: #4d4d4d;
      font-size: 50px;
    }

    .home-default {
      font-size: 20px;
    }
  }

  .home-customized {
    display: flex;
    flex-wrap: wrap;

    //justify-content: space-between;
    margin: 0 130px;

    .home-customized-left {
      width: 480px;
      height: 660px;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
    }

    .home-customized-center:nth-child(3n-2) {
      margin-left: 0;
    }
    .home-customized-center {
      height: 500px;
      margin-left: 109px;
      margin-bottom: 20px;
      .home-customized-box {
        height: 100%;

        img {
          height: 100%;
        }

        div {
          top: unset;

          width: 100%;
          height: 149px;
          left: 0;
          bottom: 0;

          p {
            text-align: left;
            text-overflow: -o-ellipsis-lastline;
            overflow: hidden; //溢出内容隐藏
            text-overflow: ellipsis; //文本溢出部分用省略号表示
            display: -webkit-box; //特别显示模式
            -webkit-line-clamp: 2; //行数
            line-clamp: 2;
            -webkit-box-orient: vertical; //盒子中内容竖直排列
          }
        }
      }
    }

    .home-customized-box {
      position: relative;

      div {
        position: absolute;
        width: 290px;
        height: 60px;
        background-color: rgba(0, 0, 0, 0.3);
        top: 130px;
        left: 85px;

        p {
          line-height: 60px;
          text-align: center;
          color: white;
          font-size: 30px;
        }
      }

      img {
        float: left;
        width: 480px;
        height: 320px;
      }
    }
  }

  .goods-box {
    .goods-list {
      //margin-left: 130px;
      width: 1660px;
      display: grid;
      grid-template-columns: 34% 34% 34%;

      .goods-item {
        margin-top: 43px;
        width: 526px;
        height: 250px;
        background: #f8f8f8;
        border-radius: 5px;
        box-shadow: 0px 3px 6px 0px rgba(0, 0, 0, 0.16);
        display: flex;

        .goods-info {
          width: 210px;
          height: 25px;

          .goods-title {
            margin-top: 23px;
            width: 256px;
            font-size: 18px;
            font-family: PingFang SC, PingFang SC-Regular;
            font-weight: 400;
            text-align: center;
            color: #4d4d4d;
            margin-bottom: 15px;
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
          }

          .goods-describe {
            margin-left: 12px;
            width: 180px;
            height: 130px;
            font-size: 12px;
            font-family: PingFang SC, PingFang SC-Regular;
            font-weight: 400;
            text-align: left;
            color: #b4b4b4;
            word-break: break-all;
            display: -webkit-box; /**对象作为伸缩盒子模型展示**/
            -webkit-box-orient: vertical; /**设置或检索伸缩盒子对象的子元素的排列方式**/
            -webkit-line-clamp: 7; /**显示的行数**/
            overflow: hidden; /**隐藏超出的内容**/
          }
        }

        .img {
          width: 260px;
          height: 250px;
          background: #dedede;
          border-radius: 5px;
        }

        .goods-price {
          display: flex;
          justify-content: space-between;
          margin-left: 12px;
          min-width: 233px;

          .price {
            width: 92px;
            //word-break: break-all;
            //display: -webkit-box; /**对象作为伸缩盒子模型展示**/
            //-webkit-box-orient: vertical; /**设置或检索伸缩盒子对象的子元素的排列方式**/
            //-webkit-line-clamp: 1; /**显示的行数**/
            //overflow: hidden; /**隐藏超出的内容**/
            font-size: 20px;
            font-family: PingFang SC, PingFang SC-Regular;
            font-weight: 400;
            text-align: left;
            color: #333333;
          }

          .btn-box {
            //flex: 1;
            display: flex;
            align-items: center;
            justify-content: center;
            min-width: 66px;
            //margin-left: 60px;

            .btn {
              cursor: pointer;
              min-width: 66px;
              height: 23px;
              background: #ffffff;
              border-radius: 12px;
              box-shadow: 0px 2px 3px 0px rgba(0, 0, 0, 0.16);
              font-size: 12px;
              font-family: PingFang SC, PingFang SC-Regular;
              font-weight: 400;
              text-align: left;
              color: #b4b4b4;
              display: flex;
              align-items: center;
              justify-content: center;

              white-space: nowrap;
              overflow: hidden;
              text-overflow: ellipsis;
            }
          }
        }
      }
    }

    .more {
      margin-top: 25px;
      width: 100%;
      height: 30px;
      display: flex;
      align-items: center;
      justify-content: center;

      .more-btn {
        cursor: pointer;
        width: 134px;
        height: 27px;
        font-size: 12px;
        color: #b4b4b4;
        background: #ffffff;
        border-radius: 14px;
        box-shadow: 0px 2px 3px 0px rgba(0, 0, 0, 0.16);
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }
  }

  .news-box {
    //margin-left: 130px;
    margin-top: 140px;
    display: flex;

    #news {
      margin-left: 104px;
      width: 830px;
      height: 591px;
      background: #fcfcfc;
      border-radius: 5px;
      box-shadow: 0px 3px 6px 0px rgba(0, 0, 0, 0.16);

      .new-list-box {
        margin-left: 38px;

        .new-item {
          margin-top: 29px;
          //margin-bottom: 29px;
          display: flex;

          .new-img {
            width: 162px;
            height: 124px;
            background: rgba(255, 255, 255, 1);
          }

          .new-info {
            margin-left: 36px;
            width: 591px;

            .new-title {
              //height: 20px;
              font-size: 15px;
              font-family: PingFang SC, PingFang SC-Medium;
              font-weight: 500;
              text-align: left;
              color: #4d4d4d;
              display: -webkit-box; /**对象作为伸缩盒子模型展示**/
              -webkit-box-orient: vertical; /**设置或检索伸缩盒子对象的子元素的排列方式**/
              -webkit-line-clamp: 1; /**显示的行数**/
              overflow: hidden; /**隐藏超出的内容**/
            }

            .new-new {
              margin-top: 6px;
              height: 68px;
              font-size: 12px;
              font-family: PingFang SC, PingFang SC-Regular;
              font-weight: 400;
              text-align: left;
              color: #808080;
              display: -webkit-box; /**对象作为伸缩盒子模型展示**/
              -webkit-box-orient: vertical; /**设置或检索伸缩盒子对象的子元素的排列方式**/
              -webkit-line-clamp: 2; /**显示的行数**/
              overflow: hidden; /**隐藏超出的内容**/
            }

            .new-date {
              //height: 18px;
              font-size: 13px;
              font-family: PingFang SC, PingFang SC-Regular;
              font-weight: 400;
              text-align: left;
              color: #333333;
            }
          }
        }
      }
    }

    .we-box {
      width: 726px;
      height: 591px;
      background: #fcfcfc;
      border-radius: 5px;
      box-shadow: 0px 3px 6px 0px rgba(0, 0, 0, 0.16);

      .item-head {
        .head-title {
          margin-left: 39px;
          flex: 1;
          height: 40px;
          display: flex;
          align-items: center;
          font-size: 27px;
          font-family: PingFang SC, PingFang SC-Bold;
          font-weight: 700;
          text-align: center;
          color: #4d4d4d;
        }
      }

      .we-new-list {
        margin-left: 39px;

        .we-item {
          margin-top: 28px;
          margin-bottom: 39px;
          width: 653px;
          height: 125px;
          display: flex;

          .item-img {
            width: 187px;
            height: 125px;
            background: rgba(255, 255, 255, 1);

            img {
              width: 187px;
              height: 125px;
            }
          }

          .item-info {
            width: 444px;
            margin-left: 22px;

            .info-title {
              height: 28px;
              font-size: 18px;
              font-family: PingFang SC, PingFang SC-Medium;
              font-weight: 500;
              text-align: left;
              color: #4d4d4d;
              display: -webkit-box; /**对象作为伸缩盒子模型展示**/
              -webkit-box-orient: vertical; /**设置或检索伸缩盒子对象的子元素的排列方式**/
              -webkit-line-clamp: 1; /**显示的行数**/
              overflow: hidden; /**隐藏超出的内容**/
            }

            .info-describe {
              margin-top: 8px;
              height: 71px;
              font-size: 12px;
              font-family: PingFang SC, PingFang SC-Regular;
              font-weight: 400;
              text-align: left;
              color: #808080;
              word-break: break-all;
              display: -webkit-box; /**对象作为伸缩盒子模型展示**/
              -webkit-box-orient: vertical; /**设置或检索伸缩盒子对象的子元素的排列方式**/
              -webkit-line-clamp: 7; /**显示的行数**/
              overflow: hidden; /**隐藏超出的内容**/
            }

            .info-date {
              height: 18px;
              font-size: 13px;
              font-family: PingFang SC, PingFang SC-Regular;
              font-weight: 400;
              text-align: left;
              color: #333333;
            }
          }
        }

        .we-itema {
          width: 635px;
          border-bottom: 2px dashed #999999;

          .item-info {
            display: flex;
            justify-content: space-between;
            margin-bottom: 9px;

            .info-title {
              // min-width: 392px;
              width: 240px;
              font-size: 17px;
              font-family: PingFang SC, PingFang SC-Regular;
              font-weight: 400;
              text-align: left;
              color: #4d4d4d;
              overflow: hidden;
              white-space: nowrap;
              /*文字超出宽度则显示ellipsis省略号*/
              text-overflow: ellipsis;
            }

            .info-date {
              font-size: 17px;
              font-family: PingFang SC, PingFang SC-Regular;
              font-weight: 400;
              text-align: left;
              color: #4d4d4d;
            }
          }
        }
      }
    }
  }

  .item-head {
    display: flex;
    margin-top: 35px;

    .new-header {
      display: flex;

      .head-title {
        cursor: pointer;
        margin-left: 39px;
        flex: 1;
        height: 40px;
        display: flex;
        align-items: center;
        font-size: 27px;
        font-family: PingFang SC, PingFang SC-Bold;
        font-weight: 700;
        text-align: center;
        color: #4d4d4d;

        //width: 150px;
        white-space: nowrap;
        //overflow: hidden;
        text-overflow: ellipsis;
      }
    }

    .head-btn-box {
      flex: 1;
      height: 40px;
      display: flex;
      align-items: center;
      justify-content: flex-end;

      .head-btn {
        cursor: pointer;
        min-width: 90px;
        height: 27px;
        background: #ffffff;
        border-radius: 14px;
        box-shadow: 0px 2px 3px 0px rgba(0, 0, 0, 0.16);
        font-size: 12px;
        font-family: PingFang SC, PingFang SC-Regular;
        font-weight: 400;
        color: #b4b4b4;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-right: 39px;
      }
    }
  }
}
</style>
